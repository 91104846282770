<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">あなたが司書資格を取得した年代を選んで下さい。司書課程を履修した大学や短期大学の卒業年にあたります。<span class="star">*</span><br/>（通信課程や夏休み等に開講される司書講習で資格を取得された場合や司書教諭資格は本調査の対象外です）</p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="stateQ1"
					label="司書資格を取得した年代"
					:items="certificate_years"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				>
					<template v-slot:item="{ item, attrs, on }">
						<v-list-item v-bind="attrs" v-on="on">
							<v-list-item-title class="text-wrap">
								{{ item.text }}
							</v-list-item-title>
						</v-list-item>
					</template>
				</v-select>
			</v-col>
		</v-row>
		<!-- <v-row>
			<v-col>
				<v-chip-group
					v-model="stateQ1"
					active-class="primary--text"
					column
				>
					<v-chip
						v-for="certificate_year in certificate_years"
						:key="certificate_year.value"
						:value="certificate_year.value"
						filter
					>
						{{ certificate_year.text }}
					</v-chip>
				</v-chip-group>
			</v-col>
		</v-row> -->
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}



</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurveyQ1',
	data () {
		return {
			form: {
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			]
		}
	},
	computed: {
		...mapGetters({
			Q1: 'stateQ1'
		}),
		stateQ1: {
			get () {
				return this.Q1
			},
			set (value) {
				this.setQ1(value)
			}
		}
	},
	methods: {
		...mapActions([
			'setPublicSurveyAnswer',
			'setQ1',
		]),
	}
}
</script>